import { Action } from 'redux';
import { Certificates } from '../../models/Certificates';

export const CERTIFICATES_LOAD_REQUEST = 'CERTIFICATES_LOAD_REQUEST';
export const CERTIFICATES_LOAD_SUCCESS = 'CERTIFICATES_LOAD_SUCCESS';
export const CERTIFICATES_LOAD_FAIL = 'CERTIFICATES_LOAD_FAIL';
export const CERTIFICATES_RESET = 'CERTIFICATES_RESET';

export interface CertificatesLoadRequest extends Action<typeof CERTIFICATES_LOAD_REQUEST> {}
export interface CertificatesLoadSuccess extends Action<typeof CERTIFICATES_LOAD_SUCCESS> {
    payload: {
        fetchedCerts: Certificates[];
    };
}
export interface CertificatesLoadFail extends Action<typeof CERTIFICATES_LOAD_FAIL> {
    payload: {
        fetchError: string;
    };
}
export interface CertificatesReset extends Action<typeof CERTIFICATES_RESET> {}
