import { Certificates } from '../../models/Certificates';
import {
    CertificatesLoadFail,
    CertificatesLoadRequest,
    CertificatesLoadSuccess,
    CertificatesReset,
    CERTIFICATES_LOAD_FAIL,
    CERTIFICATES_LOAD_REQUEST,
    CERTIFICATES_LOAD_SUCCESS,
    CERTIFICATES_RESET,
} from './certificatesTypes';

interface State {
    fetchedCerts: Certificates[] | null;
    isFetching: boolean;
    fetchError: string;
}

const INITIAL_STATE: State = {
    fetchedCerts: null,
    isFetching: false,
    fetchError: '',
};

export const certificatesReducer = (
    state: State = INITIAL_STATE,
    action: CertificatesLoadRequest | CertificatesLoadSuccess | CertificatesLoadFail | CertificatesReset,
) => {
    switch (action.type) {
        case CERTIFICATES_LOAD_REQUEST: {
            return {
                ...state,
                isFetching: true,
                fetchError: '',
                fetchedCerts: null,
            };
        }
        case CERTIFICATES_LOAD_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                fetchError: '',
                fetchedCerts: action.payload.fetchedCerts,
            };
        }
        case CERTIFICATES_LOAD_FAIL: {
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload.fetchError,
                fetchedCerts: null,
            };
        }
        case CERTIFICATES_RESET: {
            return {
                ...state,
                isFetching: false,
                fetchError: '',
                fetchedCerts: null,
            };
        }
        default:
            return state;
    }
};
