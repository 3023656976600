import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';

const LoadingSpinner: React.FC = () => {
    const tokens = {
        sectionStack: {
            childrenGap: 10,
        },
        spinnerStack: {
            childrenGap: 20,
        },
    };

    return (
        <div style={{ paddingBottom: '40px', paddingTop: '20px' }}>
            <Stack tokens={tokens.sectionStack}>
                <Stack tokens={tokens.spinnerStack}>
                    <Spinner label="Loading..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.large} />
                </Stack>
            </Stack>
        </div>
    );
};

export default LoadingSpinner;
