import { combineReducers } from 'redux';
import { certificatesReducer } from './certificates/certificatesReducer';
import { searchReducer } from './search/searchReducer';

const rootReducer = combineReducers({
    certificates: certificatesReducer,
    search: searchReducer,
});

export default rootReducer;
