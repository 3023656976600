import { mergeStyleSets } from '@fluentui/merge-styles';

export interface IComponentClassNames {
    header: string;
    logo: string;
    navigationContainer: string;
    navigationOptions: string;
}

export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            '.nav': {
                width: '70%',
            },
        },
        logo: {
            display: 'block',
            '@media (max-width: 1024px)': {
                width: '85%',
                height: 'auto',
            },
        },
        navigationContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            overflow: 'hidden',
            alignItems: 'center',
        },
        navigationOptions: {
            width: '100%',
        },
    });
};
