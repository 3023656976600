import { Action } from "redux";

export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_STATUS = 'SEARCH_STATUS';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export interface SearchQuery extends Action<typeof SEARCH_QUERY> {
    payload: {
        searchQuery: string;
    };
}

export interface SearchStatus extends Action<typeof SEARCH_STATUS> {
    payload: {
        searchStatus: boolean;
    };
}

export interface SearchError extends Action<typeof SEARCH_ERROR> {
    payload: {
        searchError: boolean;
    };
}

export interface SearchReset extends Action<typeof SEARCH_RESET> {}