import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { initializeIcons, ThemeProvider } from '@fluentui/react/';
import { appTheme } from './theme/theme';
import './App.css';

import Header from './components/header/Header';
import LoadingSpinner from './components/loadingSpinner/LoadingSpinner';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';

const HomePage = lazy(() => import('./pages/homePage/HomePage'));
const CertsPage = lazy(() => import('./pages/certsPage/CertsPage'));
const NotFoundPage = lazy(() => import('./pages/notFoundPage/NotFoundPage'));

initializeIcons();

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={appTheme}>
                <Header />
                <ErrorBoundary>
                    <Suspense fallback={<LoadingSpinner />}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/certs/:certsId(Sterility|Analysis|Origin)" component={CertsPage} />
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
