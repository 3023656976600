import { ICommandBarStyles } from '@fluentui/react/lib/CommandBar';
import { appTheme } from '../../theme/theme';

const theme = appTheme;

export const navStyles: ICommandBarStyles = {
    root: {
        padding: 0,
    },
    primarySet: {
        justifyContent: 'space-between',
    },
};

export const navigationStyles = {
    itemProps: {
        styles: {
            linkContent: {
                color: theme.palette.themePrimary,
                fontWeight: 'bold',
            },
            root: {
                color: theme.palette.themePrimary,
                fontWeight: 'bold',
            },
        },
    },
    buttonStyles: {
        labelHovered: { color: '#004f97' },
        root: {
            color: theme.palette.themePrimary,
        },
        label: {
            fontWeight: 'bold',
        },
    },
};

export const navigationSubMenuStyles = {
    itemProps: {
        styles: {
            linkContent: {
                color: theme.palette.themePrimary,
                fontWeight: 'bold',
            },
            root: {
                color: theme.palette.themePrimary,
                fontWeight: 'bold',
            },
        },
    },
    buttonStyles: {
        rootExpanded: {
            color: theme.palette.themePrimary,
        },
        labelHovered: {
            color: theme.palette.themePrimary,
        },
        root: {
            color: theme.palette.themePrimary,
        },
        label: {
            fontWeight: 'bold',
        },
    },
};
