import { SearchError, SearchQuery, SearchReset, SearchStatus,  SEARCH_ERROR,  SEARCH_QUERY, SEARCH_RESET, SEARCH_STATUS } from "./searchTypes"

interface State {
    searchQuery: string;
    searchStatus: boolean;
    searchError: boolean;
}

const INITIAL_STATE: State = {
    searchQuery: '',
    searchStatus: false,
    searchError: false,
}

export const searchReducer = (state: State = INITIAL_STATE, action: SearchQuery | SearchStatus| SearchError | SearchReset ) => {
    switch (action.type) {
        case SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload.searchQuery,
            }
        }
        case SEARCH_STATUS: {
            return {
                ...state,
                searchStatus: action.payload.searchStatus,
            };
        }
        case SEARCH_ERROR: {
            return {
                ...state,
                searchError: action.payload.searchError
            }
        }
        case SEARCH_RESET: {
            return {
                searchQuery: '',
                searchStatus: false,
                searchError: false
            }
        }
        default:
            return state
    }
}